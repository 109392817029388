<template>
  <div class="info-wrap">
    <van-sticky :offset-top="60">
      <div class="line"></div>
    </van-sticky>

    <!-- 标签 -->
    <van-sticky :offset-top="50">
      <div class="label" style="background: #14151e;">
        <div :class="{ 'active': tab === 1 }" @click="changeTab(1)">个人资料</div>
        <div :class="{ 'active': tab === 2 }" @click="changeTab(2)">评价</div>
      </div>

    </van-sticky>
    <van-sticky :offset-top="110">
      <div class="dot-line-detail"></div>
    </van-sticky>

    <template v-if="tab === 1">
      <div class="user-detail">
        <!-- 个性签名-->
        <div class="info-title">{{ $t('userDetail.selfDesc') }}</div>
        <div class="info-intro">{{ userInfo.description }}</div>

        <!-- 相册-->
        <div class="photo-title">{{ $t('userDetail.album') }}</div>

        <div :class="userInfo.photoList && userInfo.photoList.length > 0 ? 'photo-list photo-list-height' : 'photo-list'">
          <van-swipe class="my-swipe" indicator-color="white" :loop="false" :width="165" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in userInfo.photoList" :key="index">
              <van-image :src="item" @click="previewPhotos(index)" fit="contain" />
            </van-swipe-item>
          </van-swipe>
        </div>

        <!-- 基本信息-->
        <div class="info-base">
          <div class="base-item">
            <div class="base-item-left"> {{ $t('userDetail.height') }}</div>
            <div class="base-item-right height"> <span v-if="userInfo.height"> {{ userInfo.height }}cm</span></div>
          </div>
          <div class="base-item">
            <div class="base-item-left"> {{ $t('userDetail.weight') }}</div>
            <div class="base-item-right weight"> <span v-if="userInfo.weight"> {{ userInfo.weight }}kg</span></div>
          </div>
          <div class="base-item">
            <div class="base-item-left"> {{ $t('userDetail.job') }}</div>
            <div class="base-item-right about"> <span v-if="userInfo.job"> {{ userInfo.job }}</span></div>
          </div>
          <div class="base-item">
            <div class="base-item-left">{{ $t('userDetail.temperament') }}</div>
            <div class="base-item-right temperament" v-for="(item, index) in getTemperament(userInfo.temperament)"
              :key="index">
              {{ item }}&nbsp;&nbsp;
            </div>
          </div>
          <div class="base-item">
            <div class="base-item-left">{{ $t('userDetail.offen') }}</div>
            <div class="base-item-right offen" v-for="(item, index) in userInfo.oftenList" :key="index">
              {{ item }}&nbsp;&nbsp;
            </div>
          </div>
        </div>

        <!-- 相册预览 -->
        <PersonPhoto v-if="showImage" :images="userInfo.photoList" :startPosition="startPosition"
          @close="closePrevieImage" />
      </div>

    </template>

    <template v-if="tab === 2">
      <UserReview :userId="userInfo._id" />
    </template>

  </div>
</template>

<script>
import PersonPhoto from './PersonPhoto.vue';
import UserReview from './UserReview.vue';

export default {
  name: '',
  components: { PersonPhoto, UserReview },
  props: ['userInfo', 'photosShow'],
  data() {
    return {
      showImage: false,
      startPosition: 1,
      tab: 1,
    }
  },
  methods: {
    changeTab(tab) {
      if (this.tab === tab) return
      this.tab = tab
    },
    previewPhotos(index) {
      this.startPosition = index
      this.showImage = true
    },
    closePrevieImage() {
      this.showImage = false
      this.startPosition = 0
    },
    getTemperament(temperament) {
      let temperaments = []
      if (temperament) {
        temperaments = temperament.split(',')
      }
      return temperaments
    }
  },
  mounted() {

  }
}
</script>


<style lang="scss" scoped>
.info-wrap {
  background: #14151E;
  margin-top: 10px;

  .line {
    width: 100%;
    height: 1px;
    background: rgba(61, 63, 81, 1);
  }

  .label {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // padding: 20px;
    height: 60px;
    font-size: 16px;
    color: #fff;
    font-weight: bold;

    >div {
      padding-bottom: 3px;
      // width: 48%;
    }

    .active {
      border-bottom: 2px solid #FD2084;
      border-radius: 1px;
    }
  }

  .dot-line-detail {
    border: 0.5px dashed #3D3F51;
    height: 0;
    width: calc(100% - 40px);
    margin-left: 20px;
  }

  .user-detail {
    min-height: 500px;

    .info-title {
      padding: 20px 0 10px 20px;
      width: 100%;
      font-size: 14px;
    }

    .info-intro {
      color: rgba(255, 255, 255, 0.5);
      line-height: 1.2em;
      white-space: pre-wrap;
      padding: 0 20px;
      color: #FFF7DC;
      font-size: 14px;
      font-weight: 600;
      min-height: 10px;
    }

    .photo-title {
      font-size: 14px;
      margin: 20px 0 10px 20px;
    }

    .photo-list {
      padding-left: 20px;
      padding-right: 10px;


      .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        text-align: center;
        background-color: #14151E;
        margin-right: 10px;
        border-radius: 4px;
      }

      .van-swipe {
        height: 100%;

        .van-image {
          height: 100%;
          width: 100%;

          /deep/img {
            object-fit: cover !important;
            border-radius: 6px;
          }
        }
      }
    }

    .photo-list-height {
      height: 200px;
    }

    .info-base {
      padding: 10px 0 0 20px;

      >div:last-child {
        padding-bottom: 30px;
      }

      .base-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 10px 0;

        .base-item-left {
          color: #fff;
          font-size: 14px;
          margin-right: 20px;
        }

        .base-item-right {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
        }

        .height {
          color: #CBAC41;
        }

        .weight {
          color: #FF46CB;
        }

        .temperament {
          color: #FD2084;
        }

        .offen {
          color: #AB77F8;
        }

        .about {
          color: #6CC7FF;
        }
      }
    }

  }
}
</style>