<template>
  <div class="review-wrap">

    <template v-if="comments.length > 0">
      <van-sticky :offset-top="110">
        <!-- 评论总分 -->
        <!--        <van-sticky :offset-top="110">-->
        <!--          <div class="dot-line-detail"></div>-->
        <!--        </van-sticky>-->
        <div class="review-stars" style="background: #14151e;">
          <div class="total-stars">
            <span>{{ totalComment.totalAvg }}</span>
            <van-rate v-model="totalComment.totalAvg" count="5" readonly allow-half color="#ffd21e" void-color="#eee"
              gutter="10px" />
          </div>
          <div class="avg-stars">
            <div><span>颜值</span><span>{{ totalComment.looksAvg }}</span></div>
            <div><span>服务</span><span>{{ totalComment.serverAvg }}</span></div>
            <div><span>推荐指数</span><span>{{ totalComment.attitudeAvg }}</span></div>
          </div>
          <div class="title">评论列表</div>
          <div class="dot-line"></div>
        </div>

      </van-sticky>


      <!-- 评论列表 -->
      <div class="review-list">



        <div class="content-container">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :loading-text="$t('tip.loading')"
            :loosing-text="$t('tip.refreshing')" :pulling-text="$t('tip.refresh')"
            :success-text="$t('tip.refreshSuccess')">
            <van-list ref="list" class="activelist" v-model="loading" :immediate-check="false" :finished="finished"
              :finished-text="$t('tip.noMore')" @load="onReachBottom">
              <div class="con-item" v-for="(item, index) in showComments" :key="index"
                @click="changeShowOrHideReview(item._id)">
                <div class="item-title">
                  <img :src="item.headPhoto" />
                  <div class="info">
                    <div class="name">
                      {{ item.nickname.slice(0, 2) + '***' + item.nickname.slice(item.nickname.length - 2,
                        item.nickname.length) }}
                      <div class="tag" v-if="item.cherryPick === '1'">
                        <img src="@/assets/img/user/tagBg.png" alt="">
                        <span>精选</span>
                      </div>
                    </div>
                    <div class="time">{{ item.createdAt }}</div>
                  </div>
                  <div class="rate">
                    <div class="left">
                      <van-rate v-model="item.average" count="5" readonly allow-half color="#ffd21e" void-color="#eee"
                        size="14px" gutter="6px" />
                      <span>{{ item.average }}</span>
                    </div>
                  </div>
                </div>

                <div :id="`review-${item._id}`" class="item-content ellipsis-m3" v-html="formatText(`${item.content}`)">
                </div>
                <div :id="`review-${item._id}-down`" class="down">
                  <img src="@/assets/img/icon/arrow-down.svg" />
                </div>
                <div :id="`review-${item._id}-up`" class="up hide">
                  <img src="@/assets/img/icon/arrow-up.svg" />
                </div>
                <div class="dot-line"></div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </div>
    </template>

    <!-- 无评论数据 -->
    <template v-else>
      <div class="no-data">
        <div class="tip">{{ noComment }}</div>
      </div>
    </template>
  </div>
</template>

<script>

import {cherryPickCommentList, commentList} from '@/service/comment'
import { mapState } from "vuex";

export default {
  name: '',
  props: {
    userId: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      refreshing: false, // 下拉刷新状态
      loading: false, // 加载状态
      finished: false,
      oneStar: 1,
      yzStar: 0,
      fwStar: 0,
      tdStar: 0,
      pageSize: 5,
      pageNum: 1,
      total: 0,
      noComment: '',
      totalComment: {}, // 总体评价
      comments: [], // 评价列表
      pickComments: [] // 精选评论列表
    }
  },
  computed: {
    ...mapState(['lang']),
    showComments () {
      return [...this.pickComments, ...this.comments]
    }
  },
  methods: {
    async init() {
      if (this.userId) {
        await this.cherryPickCommentList()
        await this.getCommentList()
      }
    },
    async getCommentList() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        userId: this.userId
      }
      this.loading = true
      const resp = await commentList(params)
      this.loading = false
      if (resp.code === 200) {
        this.totalComment = resp.data.comment
        this.total = resp.data.total
        this.formatStars()
        const retComments = this.formatComments(resp.data.rows)

        if (this.pageNum === 1) {
          this.comments = retComments
        } else {
          this.comments.push(...retComments)
        }
        if (this.comments.length === 0) {
          this.noComment = '暂无评论~'
        }
        this.finished = this.pageNum * this.pageSize >= this.total;
      }
    },
    formatComments(rows) {
      let tempComments = rows
      if (tempComments.length > 0) {
        tempComments = tempComments.map(c => {
          c.average = this.calcStar(c.average)
          c.createdAt = this.formatTime(c.createdAt)
          if (c.headPhoto) {
            c.headPhoto = this.$gbUtils.formatImgUrl(c.headPhoto)
          } else {
            c.headPhoto = require('@/assets/img/user-default.png')
          }
          return c
        })
      }
      return tempComments
    },
    async cherryPickCommentList() {
      const params = {
        userId: this.userId
      }
      const resp = await cherryPickCommentList(params)
      console.log('cherryPickCommentList resp==', resp)
      if (resp.code === 200) {
        this.pickComments = this.formatComments(resp.data)
      }
    },
    formatStars() {
      this.totalComment.totalAvg = this.calcTotalAvg(this.totalComment.totalAvg)
      this.totalComment.looksAvg = this.calcStar(this.totalComment.looksAvg)
      this.totalComment.serverAvg = this.calcStar(this.totalComment.serverAvg)
      this.totalComment.attitudeAvg = this.calcStar(this.totalComment.attitudeAvg)
      this.yzStar = this.totalComment.looksAvg
      this.fwStar = this.totalComment.serverAvg
      this.tdStar = this.totalComment.attitudeAvg
    },
    // 计算颜值，服务，态度 分数
    calcStar(num) {
      if (num === undefined) {
        return 0
      }
      if (Number.isInteger(num)) {
        return parseFloat(num + ".0").toFixed(1);
      } else {
        let integerPart = Math.floor(num);
        let decimalPart = num - integerPart;
        if (decimalPart > 0.5) {
          return parseFloat((integerPart + 1) + ".0").toFixed(1);
        } else {
          return integerPart + 0.5;
        }
      }
    },
    // 计算总评价分数，保留2位小数
    calcTotalAvg(num) {
      if (num === undefined) {
        return 0
      }
      return parseFloat(num.toFixed(1))
    },
    formatText(str) {
      return str.replace(/\n/g, '<br/>')
    },
    formatTime(str) {
      return this.$gbUtils.formatActiveTime2(str, this.lang)
    },
    changeShowOrHideReview(id) {
      const rId = 'review-' + id
      const dId = 'review-' + id + '-down'
      const uId = 'review-' + id + '-up'
      if (document.getElementById(rId).classList.contains('ellipsis-m3')) {
        document.getElementById(dId).style.display = 'none'
        document.getElementById(uId).style.display = 'block'
        document.getElementById(rId).classList.remove('ellipsis-m3')
      } else {
        document.getElementById(uId).style.display = 'none'
        document.getElementById(dId).style.display = 'block'
        document.getElementById(rId).classList.add('ellipsis-m3')
      }
    },
    // 下拉刷新
    async onRefresh() {
      console.log('reach onRefresh ...')
      this.pageNum = 1
      this.pageSize = 5
      this.total = 0
      this.refreshing = true
      await this.getCommentList()
      this.refreshing = false;
    },
    // 上滑刷新
    async onReachBottom() {
      console.log('onReachBottom ....')
      if (this.pageNum * this.pageSize < this.total) {
        this.pageNum++
      } else {
        this.finished = true;
        return
      }
      await this.getCommentList()
    },
    reSortComment() {

    }
  },
  created() {
    this.init()
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.review-wrap {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  margin-top: 10px;

  .review-stars {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: bold;
    padding: 30px 0 0 0;

    .total-stars {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: baseline;

      span {
        margin-right: 16px;
        font-weight: bold;
      }
    }

    .avg-stars {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 20px;
      align-items: center;

      >div {
        font-size: 16px;
        font-weight: bold;
        border-right: 1px solid #fff;
        margin-left: 20px;
        padding-right: 20px;

        :last-child {
          margin-left: 10px;
        }
      }

      :last-child {
        border-right: none;
        padding-right: 0;
      }
    }


  }

  .van-sticky--fixed .review-stars {
    padding: 30px 20px 0 20px;
  }

  .title {
    font-size: 14px;
    color: #FD2084;
    margin-top: 30px;
  }

  .review-list {
    padding-top: 20px;

    .content-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      overflow-y: scroll;
      max-height: 620px;

      .con-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;

        .item-title {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          height: 40px;
          align-items: center;
          position: relative;

          >img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid #fff;
            position: absolute;
            left: 0;
            top: 0;
          }

          .info {
            width: calc(100% - 50px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #A490E3;
            font-size: 16px;
            margin-left: 50px;
            display: flex;
            align-items: center;

            .name {
              display: flex;
              align-items: center;
              flex: 1;

              .tag {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 15px;

                img {
                  position: absolute;
                  width: 40px;
                  height: 20px;
                }

                span {
                  font-size: 12px;
                  color: #ffffff;
                  position: relative;
                  z-index: 1;
                }
              }
            }


            .time {
              font-size: 14px;
              color: #fff;
            }
          }

          .rate {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            margin-left: 50px;

            .left {
              span {
                font-size: 14px;
                font-weight: bold;
                margin-left: 10px;
              }
            }

            .right {
              display: flex;
              justify-content: center;
              align-items: center;

              >div {
                span {
                  font-size: 14px;
                  font-weight: bold;
                  margin-right: 6px;
                }
              }

              >div:last-child {
                span {
                  margin-right: 0;
                }
              }
            }
          }

        }

        .item-content {
          margin-top: 10px;
          line-height: 1.5;
          color: #a3a3a3;
          font-size: 14px;
        }

        .down,
        .up {
          margin: 5px 0;
          width: 100%;
          text-align: center;
        }

        .hide {
          display: none;
        }
      }
    }
  }

  .dot-line {
    width: 100%;
    border: 1px solid #1D1E2B;
    height: 0;
    // margin-top: 20px;
  }

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
    padding: 50px 0;
  }
}

.dot-line-detail {
  border: 0.5px dashed #3D3F51;
  height: 0;
  width: calc(100% - 40px);
  margin-left: 20px;
}

/deep/.van-rate__item:not(:last-child) {
  padding-right: 10px;
}
</style>
