<!--相册预览Pop-->
<template>
  <div class="photo-box">
    <van-image-preview v-model="show" 
      :images="images"
      :startPosition="startPosition"
      closeable
      @close="onClose"
      @change="onChange">
    </van-image-preview>
  </div>
</template>
<script>
export default {
  name: '',
  props: ['startPosition','images'],
  data() {
    return {
      show: true
    }
  },
  methods: {
    onChange(index) {
      this.index = index;
    },
    onClose() {
      console.log('onClose....')
      this.show = false
      this.$emit('close')
    }
  }
}
</script>


<style scoped lang="scss">


</style>